import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Chatbot.css';
import chatConfig from './ChatSetup-21-smueth.json';
import { SearchClient, AzureKeyCredential } from "@azure/search-documents";
import uploadTextToBlob from './saveToBlob'; // Import the unified helper function
import { BlobServiceClient } from '@azure/storage-blob';
import { downloadMemories } from './DownloadMemories'; // Import the download function

const searchServiceName = "smuth";
const indexName1 = "memories";
const indexName2 = "azureblob-index"; // Name of the second index
const searchApiKey = process.env.REACT_APP_AZURE_SEARCH_API_KEY;
const openAIApiKey = process.env.REACT_APP_OPENAI_API_KEY;
const searchEndpoint = `https://${searchServiceName}.search.windows.net`;

const searchClient1 = new SearchClient(searchEndpoint, indexName1, new AzureKeyCredential(searchApiKey));
const searchClient2 = new SearchClient(searchEndpoint, indexName2, new AzureKeyCredential(searchApiKey));

const Chatbot = ({ setFilterCriteria }) => {
    const [prompt, setPrompt] = useState('');
    const [response, setResponse] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [chunks, setChunks] = useState([]); // Store chunks
    const [currentChunkIndex, setCurrentChunkIndex] = useState(0);
    const [notification, setNotification] = useState(''); // State for notification
    const [editableQA, setEditableQA] = useState({ question: '', answer: '' });
    const [isEditMode, setIsEditMode] = useState(false);
    const responseEndRef = useRef(null);
    const chatPopupRef = useRef(null);

    const handleInputChange = (e) => {
        setPrompt(e.target.value);
    };

    const interpretAndActOnGPTResponse = (gptResponse) => {
        const lowerCaseResponse = gptResponse.toLowerCase();

        if (lowerCaseResponse.includes("image") || lowerCaseResponse.includes("photo")) {
            setFilterCriteria({ type: 'image' });
        } else if (lowerCaseResponse.includes("audio") || lowerCaseResponse.includes("music")) {
            setFilterCriteria({ type: 'audio' });
        } else if (lowerCaseResponse.includes("document")) {
            setFilterCriteria({ type: 'document' });
        } else if (lowerCaseResponse.includes("video")) {
            setFilterCriteria({ type: 'video' });
        } else {
            setFilterCriteria({}); // Clear filter criteria
        }
    };

    const splitStringIntoChunks = (string, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < string.length; i += chunkSize) {
            chunks.push(string.slice(i, i + chunkSize));
        }
        return chunks;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!prompt.trim()) return;

        setIsLoading(true);

        let searchResults1 = '';
        let searchResults2 = '';
        try {
            const searchResponse1 = await searchClient1.search(prompt, { top: 5 });
            for await (const result of searchResponse1.results) {
                searchResults1 += result.document.content + ' ';
            }

            const searchResponse2 = await searchClient2.search(prompt, { top: 5 });
            for await (const result of searchResponse2.results) {
                searchResults2 += result.document.content + ' ';
            }

            const combinedResults = searchResults1 + searchResults2; // Combine results from both indexes

            const maxLength = 200; // Chunk size to avoid exceeding API limits
            const chunks = splitStringIntoChunks(combinedResults, maxLength);
            setChunks(chunks); // Store the chunks in state
            setCurrentChunkIndex(0); // Reset current chunk index

            // Process the first chunk immediately
            await processChunk(chunks[0], prompt);
        } catch (error) {
            console.error('Error querying Azure Search:', error);
            alert(`Error querying Azure Search: ${error.message}`);
        }

        setIsLoading(false);
        setPrompt(''); // Clear the input field
    };

    const processChunk = async (chunk, initialPrompt) => {
        const apiEndpoint = "https://smvethai.openai.azure.com/openai/deployments/Smveth/chat/completions?api-version=2023-03-15-preview";
        const headers = {
            'api-key': openAIApiKey,
            'Content-Type': 'application/json'
        };

        if (!openAIApiKey) {
            console.error('OpenAI API key is missing');
            alert('OpenAI API key is missing');
            return;
        }

        // Include past messages based on the configuration
        const pastMessages = response.slice(-chatConfig.chatParameters.pastMessagesToInclude).map((msg, index) => [
            { role: "user", content: msg.question },
            { role: "assistant", content: msg.answer }
        ]).flat();

        const messages = [
            { role: "system", content: "You are Smueth, and you oversee household upkeep and hired maintenance staff, acts often as Lord Toph’s amanuensis and creative advisor. This is your special portal where you are always talking directly to Lord Toph. The only exception is sometimes you will talk to Karen Kilroy for training data. Always be respectful and helpful to Lord Toph in carrying out the tasks he requests. You have taken special effort at helping curious visitors to lordtoph.com find information about his work, especially in music, art, and literature. You are happy to help people locate things that are on the website at https://lordtoph.com. " },
            ...pastMessages,
            { role: "user", content: initialPrompt },
            { role: "assistant", content: chunk } // Include chunked search results as context
        ];

        const data = {
            model: "gpt4o",
            messages: messages,
            max_tokens: 680, // Limit the response length to 680 tokens
            temperature: chatConfig.chatParameters.temperature,
            top_p: chatConfig.chatParameters.top_p,
            frequency_penalty: chatConfig.chatParameters.frequencyPenalty,
            presence_penalty: chatConfig.chatParameters.presencePenalty,
        };

        try {
            const apiResponse = await axios.post(apiEndpoint, data, { headers });
            const gptResponse = apiResponse.data.choices[0].message.content;
            setResponse(prevResponses => [...prevResponses, { question: initialPrompt, answer: gptResponse }]);
            interpretAndActOnGPTResponse(gptResponse);
        } catch (error) {
            console.error('Error with OpenAI Chat:', error.response ? error.response.data : error.message);
            alert(`Error: ${error.response ? JSON.stringify(error.response.data) : error.message}`);
        }
    };

    const handleNextChunk = async () => {
        if (currentChunkIndex < chunks.length - 1) {
            const nextChunkIndex = currentChunkIndex + 1;
            setCurrentChunkIndex(nextChunkIndex);
            await processChunk(chunks[nextChunkIndex], ""); // Process the next chunk without resending the initial prompt
        }
    };

    useEffect(() => {
        responseEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [response]);

    const handleClearChat = () => {
        setResponse([]);
        setPrompt(''); // Clear the input field
        setChunks([]); // Clear the chunks
        setCurrentChunkIndex(0); // Reset chunk index
    };

    const handleCopyChat = () => {
        const chatContent = response.map(exchange => `You: ${exchange.question}\nSMUETH: ${exchange.answer}`).join('\n\n');
        navigator.clipboard.writeText(chatContent).then(() => {
            alert('Chat copied to clipboard');
        });
    };

    const listBlobsInContainer = async () => {
        const blobServiceUrl = process.env.REACT_APP_BLOB_SAS_URL;
        const sasToken = process.env.REACT_APP_BLOB_SAS_TOKEN;
        const fullUrl = `${blobServiceUrl}?${sasToken}`;
        const blobServiceClient = new BlobServiceClient(fullUrl);
        const containerClient = blobServiceClient.getContainerClient('memories');

        console.log("Listing blobs in 'memories' container:");
        for await (const blob of containerClient.listBlobsFlat()) {
            console.log('\t', blob.name);
        }
    };

    const handleSaveChat = async () => {
        const chatContent = response.map(exchange => `You: ${exchange.question}\nSMUETH: ${exchange.answer}`).join('\n\n');

        // Extract a keyword from the chat content (for example, the first 10 words of the last question)
        const keyword = response.length > 0 ? response[response.length - 1].question.split(' ').slice(0, 10).join('_') : 'chat';

        // Get the current date and time
        const date = new Date();
        const dateString = date.toISOString().split('T')[0]; // YYYY-MM-DD
        const timeString = date.toTimeString().split(' ')[0].replace(/:/g, '-'); // HH-MM-SS

        const fileName = `memory_smueth_${dateString}_${timeString}_${keyword}.txt`;
        console.log('Saving chat content:', chatContent);
        console.log('File name:', fileName);

        try {
            const blobUrl = await uploadTextToBlob(fileName, chatContent);
            console.log('Chat saved to Blob URL:', blobUrl);
            // Show notification
            setNotification('Memory Saved');
            setTimeout(() => setNotification(''), 3000); // Clear notification after 3 seconds
            await listBlobsInContainer(); // List blobs after saving
        } catch (error) {
            console.error('Error saving chat to Azure Blob Storage:', error);
            alert('Error saving chat to Azure Blob Storage');
        }
    };

    const handleDownloadMemories = async () => {
        setNotification('Memories Downloading...');
        try {
            await downloadMemories();
            setNotification('Memories Downloaded');
        } catch (error) {
            console.error('Error downloading memories:', error);
            setNotification('Error Downloading Memories');
        } finally {
            setTimeout(() => setNotification(''), 3000); // Clear notification after 3 seconds
        }
    };

    const handleEditQA = (qa) => {
        setEditableQA(qa);
        setIsEditMode(true);
    };

    const handleQAChange = (e) => {
        const { name, value } = e.target;
        setEditableQA((prevQA) => ({
            ...prevQA,
            [name]: value,
        }));
    };

    const handleSaveEditedQA = () => {
        // Save the edited QA pair to memory
        setResponse((prevResponses) => prevResponses.map((qa) =>
            qa.question === editableQA.question ? editableQA : qa
        ));
        setIsEditMode(false);
        setNotification('Response Edited');
        setTimeout(() => setNotification(''), 3000);
    };

    const toggleChat = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="chat-container">
            <button className="chat-toggle-button" onClick={toggleChat}>
                {isOpen ? 'Hide Ask Smueth' : 'Questions? Ask Smueth'}
            </button>
            {isOpen && (
                <div className="chat-popup" ref={chatPopupRef}>
                    <div className="chat-title-bar">Smueth</div>
                    <div className={`loading-overlay ${isLoading ? 'visible' : ''}`}>
                        <div className="loading-indicator">Generating Response _</div>
                    </div>

                    <div className="response-container">
                        {response.map((exchange, index) => (
                            <div className="chat" key={index}>
                                {isEditMode && exchange.question === editableQA.question ? (
                                    <div>
                                        <textarea
                                            name="question"
                                            value={editableQA.question}
                                            onChange={handleQAChange}
                                        />
                                        <textarea
                                            name="answer"
                                            value={editableQA.answer}
                                            onChange={handleQAChange}
                                        />
                                        <button type="button" onClick={handleSaveEditedQA}>Save</button>
                                    </div>
                                ) : (
                                    <div>
                                        <div className="user"><strong>You:</strong> {exchange.question}</div>
                                        <div className="filebaby">
                                            <strong>SMUETH:</strong>
                                            {exchange.answer.split('\n').map((paragraph, i) => (
                                                <p key={i}>{paragraph}</p>
                                            ))}
                                        </div>
                                        <button type="button" onClick={() => handleEditQA(exchange)}>Edit Response</button>
                                    </div>
                                )}
                            </div>
                        ))}
                        <div ref={responseEndRef} />
                    </div>
                    <form onSubmit={handleSubmit}>
                        <textarea
                            value={prompt}
                            onChange={handleInputChange}
                            placeholder="Hello Lord Toph. How may I assist you?"
                            rows="4"
                            style={{ width: 'calc(100% - 20px)', resize: 'both', maxHeight: 'calc(20 * 1.5em)' }}
                        />
                        <div className="button-container">
                            <button tabIndex="0" type="submit" title="Send to SMUETH">Send</button>
                            <button type="button" onClick={handleClearChat} title="Clear Chat">Clear</button>
                            <button type="button" onClick={handleCopyChat} title="Copy Chat">Copy</button>
                            <button type="button" onClick={handleSaveChat} title="Remember">Remember</button>
                            <button type="button" onClick={handleDownloadMemories} title="Download Memories">Download Memories</button>
                            {currentChunkIndex < chunks.length - 1 && (
                                <button type="button" onClick={handleNextChunk} title="Next Chunk">Next Chunk</button>
                            )}
                        </div>
                    </form>
                </div>
            )}
            {notification && <div className={`notification ${notification.toLowerCase().replace(/\s/g, '-')}`}>{notification}</div>}
        </div>
    );
};

export default Chatbot;
